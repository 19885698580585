import { render, staticRenderFns } from "./PartList.vue?vue&type=template&id=f1d3afea&scoped=true&"
import script from "./PartList.vue?vue&type=script&lang=ts&"
export * from "./PartList.vue?vue&type=script&lang=ts&"
import style0 from "./PartList.vue?vue&type=style&index=0&id=f1d3afea&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1d3afea",
  null
  
)

export default component.exports