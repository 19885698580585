


































































import Vue from 'vue';
import PopUp from '@/configurator/components/utils/PopUp.vue';
import PartListPrint from '@/configurator/components/overlays/part-list/PartListPrint.vue';
import {Nullable} from '@/common/utils/types';
import {CORE_DATA_GETTERS} from '@/configurator/store/core-data';
import {KernelPartList} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import PartListEntry from '@/configurator/components/overlays/part-list/PartListEntry.vue';

import {getGeneralCustomConfig} from '@/common/utils/custom-config';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgLogo from '@/common/svgs/custom/dist/common/logo.svg';

export default Vue.extend({
  components: {
    PopUp,
    PartListPrint,
    PartListEntry,
    SvgIcon,
  },

  data() {
    return {
      perspectiveImage: null as Nullable<string>,
    };
  },

  async mounted() {
    await this.$sdkConnector.waitForLoad();
    const configurator = await this.$sdkConnector.api;
    const {image} = await configurator.preparePerspectiveImage();
    this.perspectiveImage = image;
  },

  destroyed() {
    this.perspectiveImage = null;
  },

  props: {
    onClose: Function,
  },

  computed: {
    mainComponents(): KernelPartList[] {
      if (this.$store.state.uiState.initData.groupPartList) {
        return this.$store.state.coreData.partList.perMainComponent;
      }
      const {originPart, fullList, perMainComponent} = this.$store.state.coreData.partList;
      const originPartToUse = JSON.parse(JSON.stringify(originPart)); // deep copy because we do not want to change data in store
      originPartToUse.label = (this as any).t.t('part-list.heading');
      return [{originPart: originPartToUse, fullList, perMainComponent}];
    },
    price(): string {
      return this.$store.getters[CORE_DATA_GETTERS.CURRENT_PRICE];
    },
    bounds(): string {
      // SDK returns bounds as string which is NOT toLocaleString...
      return (this as any).t.t('width-short') + ' ' + this.$store.state.coreData.bounds.width.toLocaleString() + ' / ' +
        (this as any).t.t('height-short') + ' ' + this.$store.state.coreData.bounds.height.toLocaleString() + ' / ' +
        (this as any).t.t('depth-short') + ' ' + this.$store.state.coreData.bounds.depth.toLocaleString();
    },
    title(): string {
      const customConfig = getGeneralCustomConfig();
      if (customConfig.headline) {
        return customConfig.headline;
      }

      return this.$store.state.coreState.label;
    },
    description(): string {
      const customConfig = getGeneralCustomConfig();
      if (customConfig.partListDescription_en && this.$store.state.uiState.initData.locale === 'en') {
        return customConfig.partListDescription_en;
      } else if (customConfig.partListDescription) {
        return customConfig.partListDescription;
      }

      return this.$store.state.coreState.label;
    },
    icons(): object {
      return {
        SvgLogo,
      };
    },
  },

});
