






























































import {KernelPartList} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import PartListRow from '@/configurator/components/overlays/part-list/PartListRow.vue';
import HeightToggle from '@/configurator/components/utils/HeightToggle.vue';
import Vue, {PropOptions} from 'vue';
import {formatPrice} from '@/common/utils/helper';
import {Nullable} from '@/common/utils/types';

import {createGroupParameter, sortPartListGroups} from '@/common/utils/create-part-list-content';

export default Vue.extend({

  components: {
    PartListRow,
    HeightToggle,
  },

  data() {
    return {
      showParts: false,
      params: [],
    };
  },

  props: {
    mainComponent: Object as PropOptions<KernelPartList>,
  },

  async mounted() {
    this.$data.params = await createGroupParameter(sortPartListGroups(this.$store), this.$store, null);
  },

  computed: {
    heightToggleDisabled(): boolean {
      return !this.$store.state.uiState.initData.groupPartList || this.noSubParts;
    },
    noSubParts(): boolean {
      return this.mainComponent.fullList.length === 0;
    },
    price(): Nullable<string> {
      if (!this.$store.state.uiState.initData.groupPartList) {
        return null;
      }
      const {price, currencySymbol} = this.mainComponent.fullList.reduce((acc, part) => {
        const {price: singlePrice, currencySymbol: singleCurrencySymbol, count} = part;
        if (typeof singlePrice === 'number') {
          if (acc.price === null) {
            acc.price = 0;
          }
          acc.price += singlePrice * count;
        }
        if (singleCurrencySymbol) {
          acc.currencySymbol = singleCurrencySymbol;
        }
        return acc;
      }, {price: null as Nullable<number>, currencySymbol: null as Nullable<string>});
      return formatPrice(currencySymbol, price);
    },
  },

  methods: {
    groupedComponents(): any {
      return sortPartListGroups(this.$store);
    },
  },

});
